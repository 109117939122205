import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import { useState } from "react";
import {Blocks} from "react-loader-spinner";

/*
Hello Developer! 👋
there are 5 states for the form:

- idle: The form is not submitted yet
- submitting: The form is being submitted and processed by the backend
- submitted: The form has been submitted successfully
- error: The form submission failed
- limitReached: The limit for beta testers has been reached

The form content is rendered based on the current state.
If the state is 'idle', the form is rendered.
If the state is 'submitting', a loading spinner is shown.
If the state is 'submitted', a thank you message is shown.
If the state is 'error', an error message is shown.
If the state is 'limitReached', a message indicating that the limit has been reached is shown.

Thank you and Happy Hacking! 🚀
- Stxyl
*/

registerCoreBlocks();

const App = () => {
  const [formStatus, setFormStatus] = useState('idle'); // 'idle', 'submitting', 'submitted', 'error', 'limitReached'

  console.log("Backend URL: " + process.env.REACT_APP_BACKEND_URL);

  const handleFormSubmit = async (data, { completeForm, setIsSubmitting, setSubmissionErr }) => {
    setFormStatus('submitting');
    console.log('Form data:', data);

    const { answers } = data;

    // Extract the values from the answers object
    const jsonData = {
      first_name: answers.firstName.value,
      last_name: answers.lastName.value,
      email: answers.email.value,
      platform: answers.platformChoice.value[0], // Assuming single choice
    };

    // We can see what data is going to be parsed to the backend
    console.log("JSON Data to be sent:", jsonData);

    try {
      // Send request to backend
      const response = await fetch("https://api.moosh.app/api/beta-signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonData),
      });

      // Handle response
      if (!response.ok) {
        switch (response.status) {
          case 400:
            setSubmissionErr("Invalid form data. Please try again.");
            setFormStatus('error');
            break;
          case 403:
            setSubmissionErr("Too many beta testers. Signups are closed.");
            setFormStatus('limitReached');
            break;
          default:
            setSubmissionErr("Something went wrong. Please try again.");
            setFormStatus('error');
            break;
        }
      } else {
        completeForm();
        setFormStatus('submitted');
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmissionErr("An unexpected error occurred. Please try again.");
      setFormStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderFormContent = () => {
    switch (formStatus) {
      case 'idle':
      default:
        return (
            <Form
                formId="1"
                formObj={{
                  blocks: [
                    {
                      name: "welcome-screen",
                      id: "welcomeScreen",
                      attributes: {
                        label: "Welcome to the Beta Sign Up! 🎉",
                        description:
                            "We are really excited to have you on here testing this new App!\n" +
                            "But before we can get started you must agree to the following when you\n" +
                            "sign up for the beta..." +
                            "\n<br/>\n" +
                            "\n" +
                            "- Give feedback on the app and its features\n" +
                            "\n- Report on any bugs or issues you find\n" +
                            "\n<br/>\n" +
                            "Any other questions you can contact me on these platforms\n" +
                            "\nDiscord - @stxyl\n" +
                            "\nInstagram - @oryonivanov & @oryon_dev\n" +
                            "\n<br/>\n" +
                            "Admin aside, Tap the \"Let's Start!\" button to get started 😉\n",
                      },
                    },
                    {
                      name: "short-text",
                      id: "firstName",
                      attributes: {
                        classnames: "first-block",
                        required: true,
                        label: "Let's start with your First Name",
                      },
                    },
                    {
                      name: "short-text",
                      id: "lastName",
                      attributes: {
                        classnames: "first-block",
                        required: true,
                        label: "Hey! {{field:firstName}}, now your Last Name",
                      },
                    },
                    {
                      name: "email",
                      id: "email",
                      attributes: {
                        classnames: "first-block",
                        required: true,
                        label: "Alright {{field:firstName}}, we will need your email to contact you & invite you to the beta",
                      },
                    },
                    {
                      name: "multiple-choice",
                      id: "platformChoice",
                      attributes: {
                        required: true,
                        multiple: false,
                        verticalAlign: false,
                        label: "What Platform do you use?",
                        description: "We use this information to send you the right download link for the beta.",
                        choices: [
                          {
                            label: "iPhone (iOS)",
                            value: "iOS",
                          },
                          {
                            label: "Android",
                            value: "Android",
                          },
                        ],
                      },
                    },
                  ],
                }}
                onSubmit={handleFormSubmit}
            />
        );
      case 'submitting':
        return (
            <div style={{ textAlign: "center" }}>
              <Blocks
                height="40"
                width="40"
                color="#4fa94d"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                visible={true}
            />
              <p>Processing your form, please wait...</p>
            </div>
        );
      case 'submitted':
        return (
            <div style={{ textAlign: "center" }}>
              <h1>Thank You! 🎉</h1>
              <p>
                We appreciate you signing up for the beta. <br />
                We will contact you through email for further details on how to download the beta for your platform. <br />
                If you want to invite any other people to this beta, send them this link. <br />
                Please note that spots are <b>limited</b>, so people who signed up early get priority.
              </p>
              <p>
                Follow us on Instagram for announcements!: <br />
                <a href="https://instagram.com/oryonivanov" target="_blank">@oryonivanov</a> <br />
                <a href="https://instagram.com/oryon_dev" target="_blank">@oryon_dev</a>
              </p>
            </div>
        );
      case 'error':
        return (
            <div style={{ textAlign: "center" }}>
              <h1>We aren't able to process your submission</h1>
              <p>Please refresh the page and try again.</p>
            </div>
        );
      case 'limitReached':
        return (
            <div style={{ textAlign: "center" }}>
              <h1>Sorry, we are not accepting any more signups</h1>
              <p>The limit on the number of users to be signed up has been reached.</p>
            </div>
        );
    }
  };

  return (
      <div style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {renderFormContent()}
      </div>
  );
};

export default App;